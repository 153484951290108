<template>
  <base-section
    id="welcome"
    class="grey lighten-4"
  >
    <v-responsive
      class="mx-auto"
      max-width="1000"
    >
      <base-info-title
        align="center"
        v-bind="getTitle"
      />
    </v-responsive>
    <!--<v-container class="fill-height px-4 py-12">-->
      <!--<v-row justify="center">-->
        <!--<v-col-->
          <!--cols="12"-->
          <!--md="8"-->
        <!--&gt;-->
          <!--<v-responsive-->
            <!--class="d-flex align-center"-->
          <!--&gt;-->
            <!--<base-subheading :title="$t('welcomeTitle')" />-->

            <!--<base-body>-->
              <!--{{$t('welcomeContent')}}-->
            <!--</base-body>-->

          <!--</v-responsive>-->
        <!--</v-col>-->
      <!--</v-row>-->
    <!--</v-container>-->
  </base-section>
</template>

<script>
  export default {
    name: 'SectionWelcome',

    computed: {
      getTitle () {
        return [
          {
            title: this.$t('welcomeTitle'),
            text: this.$t('welcomeContent'),
          },
        ]
      },
    },

    data: () => ({
      downloads: [
      ],
    }),
  }
</script>
